import React from "react";

import { NavLink } from "react-router-dom";

import Authorization from "../../Helpers/Authorization";
import Modal from "./Modal";

import Logo from "../../assets/images/trukkonnectlogo.png";

//import icon_set from "../../assets/images/icon_set.png";
import signout from "../../assets/images/logout.png";
//import menu_icon from "../../assets/images/icons8-menu-vertical-40.png";
import BLogo from "../../assets/images/innerpage-logo.png";
// import Dropdown from 'react-dropdown';
// import 'react-dropdown/style.css';
import ENV from "../../Helpers/env";
import { SignOutButton } from "./SignOutButton";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      openModal: false,
    };

    this.handleClickToggle = this.handleClickToggle.bind(this);
  }

  handleClickToggle = (e) => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  _onSelect = (e) => {
    console.log(e);
  };

  matrixDownload = () => {
    this.downloadManual(ENV.env.MATRIX, "Manual");
  };

  downloadManual = (fileURL, fileName) => {
    var a = document.createElement("A");
    a.href = fileURL;
    a.download = `${fileName + "_" + this.dateAndTime}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  render() {
    var username = JSON.parse(localStorage.getItem("auth_user"));
    if (username !== undefined && username["userName"] !== undefined) {
      var uname =
        username && username["userName"] && username["userName"].toUpperCase();
    }

    const { toggle } = this.state;
    return (
      <React.Fragment>
        <header className="mainHeader header-height">
          <div className="navbar-header canlogo">
            <div className="logo">
              <h1>
                <img src={BLogo} alt="logoB" className="blogo" />
              </h1>
            </div>

            <div className="logo">
              <h1>
                <img src={Logo} alt="logo" className="truck-logo-can" />
              </h1>
            </div>
          </div>
          <div className="can-signout ">
            <div className="d-flex  justify-content-between">
              {" "}
              <p className="uname-label">{username && uname}</p>{" "}
              {/* <img onClick={this.toggleModal} src={signout} className="slogo" /> */}
              <SignOutButton></SignOutButton>
            </div>
            <p className="matrix-label" onClick={this.matrixDownload}>
              Click here to download CAN Matrix Document
            </p>
          </div>
        </header>
        {this.state.openModal && (
          <Modal show={this.state.openModal} close={this.toggleModal} />
        )}
        {/* for signout : onClick={() => Authorization.logout()} */}
      </React.Fragment>
    );
  }
}
export default Header;
