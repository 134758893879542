import React, { useEffect, useState, useRef } from 'react';
import { Route, Redirect } from "react-router-dom";
import Authorization from "../../Helpers/Authorization";
import Application from "./Application";
import { useMsal } from '@azure/msal-react';
import Env from "../../Helpers/env";

/**
 * If we have a logged-in user, redirect to the home page. Otherwise, display the component.
 */
function PublicRoute({ component: Component, path: path, ...rest }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const { instance, accounts } = useMsal();
  const notInitialRender = useRef(false);

  useEffect(() => {
    const request = {
      scopes: [Env.env.AD_APP_SCOPE],
      account: accounts[0],
    };
    if (notInitialRender.current) {
      instance
        .acquireTokenSilent(request)
        .then(async response => {
          let auth_user = {}
          auth_user.userName = accounts[0].name;
          auth_user.token = response.accessToken;
          
          localStorage.setItem('auth_user', JSON.stringify(auth_user));
          // localStorage.setItem('Authorization', response.accessToken);

          if (localStorage.getItem('auth_user')) {
            setLoggedIn(true);
          }
        })
        .catch(error => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          console.log('Error in setting Scope', error);
        });
    } else {
      notInitialRender.current = true;
    }
  }, [accounts]);

  return (
    <Route
      {...rest}
      render={props => {
        if (loggedIn) {
          return <Redirect to={{ pathname: "/owner-suite" }} />;
        }

        return (
          <Application
            {...props}
            isLoggedIn={Authorization.isLoggedIn}
            auth={Authorization}
            component={Component}
            path={path}
          />
        );
      }}
    />
  );
}
// const PublicRoute = ({ component: Component, path: path, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props => {
//         if (Authorization.isLoggedIn()) {
//           return <Redirect to={{ pathname: "/owner-suite" }} />;
//         }

//         return (
//           <Application
//             {...props}
//             isLoggedIn={Authorization.isLoggedIn}
//             auth={Authorization}
//             component={Component}
//             path={path}
//           />
//         );
//       }}
//     />
//   );
// };

export default PublicRoute;
