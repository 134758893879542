//React-Core
import React from "react";
import { render } from "react-dom";

//Redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

//Reducers-File
import callAPIMiddleware from "./middleware/callAPIMiddleware";
import reducer from "./reducers";

//Router
import { Router } from "@reach/router";

//Routes-File
import { BrowserRouter, Switch } from "react-router-dom";
import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import { ROUTE } from "./config";
import Env from "./Helpers/env";

//Not-Found Component
import { NotFound } from "./components/NotFound";

//SCSS
import "./assets/scss/style.scss";
import "toastr/build/toastr.min.css";

//CSS
import * as serviceWorker from "./serviceWorker";

//MSAL
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: Env.env.AD_APP_CLIENT_ID,
    authority: Env.env.AD_TENANT_AUTHORITY_URL,
    redirectUri: Env.env.AD_REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: message => {
        console.log(message);
      },
      logLevel: 'Info',
    },
  },
});

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const middleware = [thunkMiddleware, callAPIMiddleware];

if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

//Route generating functionality
const setRoutes = () => {
  const route = ROUTE;
  return route.map((route, index) => {
    if (route.private === true) {
      return (
        <PrivateRoute
          private={route.private}
          key={index}
          path={route.path}
          meta={route.meta}
          exact={route.exact}
          component={route.component}
          sideBar={route.sideBar}
          sideBarMenu={route.sideBarMenu}
        />
      );
    } else {
      return (
        <PublicRoute
          key={index}
          path={route.path}
          meta={route.meta}
          exact={route.exact}
          section={route.component}
          component={route.component}
        />
      );
    }
  });
};
render(
  <Provider store={createStore(reducer, applyMiddleware(...middleware))}>
      <MsalProvider instance={msalInstance}>
    <BrowserRouter basename={Env.getEnv("REACT_APP_HOMEPAGE")}>
      <Switch>
        {setRoutes()}
        <PublicRoute exact={true} path="/login" component="/login" />
        <NotFound default />
      </Switch>
    </BrowserRouter>
    </MsalProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
