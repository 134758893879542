import React from "react";

import loadable from "@loadable/component";
import Loadable from "react-loadable";

//import ProfileSideBarMenu from "../src/components/Profiles/ProfileSideBarMenu";
//import ReportsSideBarMenu from "../src/components/Reports/ReportsSideBarMenu";
//import UserReportsSideBarMenu from "../src/components/Profiles/UserProfileSidebarMenu";

import Loader from "../src/components/Common/loader";
// import myFleetSideMenu, {
//   sideBarMenu1
// } from "./components/MyFleet/sideBarMenu";
import Authorization from "./Helpers/Authorization";

// site title
export const siteTitle = "Daimler Trucks";
export const siteMetaDescription = "Daimler Trucks";

export const setTitle = title => {
  document.title = title ? title : siteTitle;
};

export const setMetaDescription = description => {
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", description ? description : siteMetaDescription);
};
//const userRole = Authorization.getAuthUserRole();

export const ROUTE = [
  // Login
  {
    private: false,
    exact: true,
    path: "/",
    meta: {
      title: siteTitle,
      description: siteMetaDescription
    },
    sideBar: false,
    component: Loadable({
      loader: () => import("./components/Login"),
      loading: Loader
    })
  },
  //Login
  {
    private: false,
    exact: true,
    path: "/login",
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription
    },
    component: Loadable({
      loader: () => import("./components/Login"),
      loading: Loader
    })
  },
  //Forgot-Password
  // {
  //   private: false,
  //   path: "/forgot-password",
  //   sideBar: false,
  //   exact: true,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Forgetpassword`),
  //     loading: Loader
  //   })
  // },
  //Driver-Profile
  // {
  //   private: true,
  //   path: "/profiles/driver",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu:
  //     userRole === "ROOTADMIN" ? ProfileSideBarMenu : UserReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Profiles/DriverProfile`),
  //     loading: Loader
  //   })
  // },
  //Vehicle-Profile
  // {
  //   private: true,
  //   path: "/profiles/vehicle",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu:
  //     userRole === "ROOTADMIN" ? ProfileSideBarMenu : UserReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Profiles/VehicleProfile`),
  //     loading: Loader
  //   })
  // },
  //Dealer-Profile
  // {
  //   private: true,
  //   path: "/profiles/dealer",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu:
  //     userRole === "ROOTADMIN" ? ProfileSideBarMenu : UserReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Profiles/DealerProfile`),
  //     loading: Loader
  //   })
  // },
  //Profiles
  // {
  //   private: true,
  //   exact: true,
  //   path: "/profiles",
  //   sideBar: false,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Profiles`),
  //     loading: Loader
  //   })
  // },
  //Owner-Suites
  // {
  //   private: true,
  //   path: "/owner-suite",
  //   sideBar: false,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/OwnerSuite/index`),
  //     loading: Loader
  //   })
  // },
  {
    private: true,
    path: "/owner-suite",
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription
    },
    component: Loadable({
      loader: () => import(`./components/OwnerSuite/index`),
      loading: Loader
    })
  },
  //my-fleet
  // {
  //   private: true,
  //   exact: true,
  //   path: "/my-fleet",
  //   sideBar: true,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/MyFleet`),
  //     loading: Loader
  //   })
  // },

  //truck-utilization
  // {
  //   private: true,
  //   exact: true,
  //   path: "/truck-utilization",
  //   sideBar: true,
  //   sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/TruckUtilization`),
  //     loading: Loader
  //   })
  // },
  //My-Fleet sidemenu

  // {
  //   private: true,
  //   exact: true,
  //   path: "/driving-analysis",
  //   sideBar: false,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/DrivingAnalysis`),
  //     loading: Loader
  //   })
  // },
  // {
  //   private: true,
  //   exact: true,
  //   path: "/driver-summary",
  //   sideBar: false,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/DrivingAnalysis/DriverSummary`),
  //     loading: Loader
  //   })
  // },
  // {
  //   private: true,
  //   path: "/driver-detail",
  //   exact: true,
  //   sideBar: false,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/DrivingAnalysis/DriverDetail`),
  //     loading: Loader
  //   })
  // },

  // {
  //   private: true,
  //   path: "/daily-trip-analysis",
  //   exact: true,
  //   // sideBarMenu: myFleetSideMenu,
  //   sideBar: false,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/DailyTripAnalysis`),
  //     loading: Loader
  //   })
  // },

  //Customer-Admin
  // {
  //   private: true,
  //   path: "/profiles/customer-admin",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu:
  //     userRole === "ROOTADMIN" ? ProfileSideBarMenu : UserReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Profiles/CustomerAdmin`),
  //     loading: Loader
  //   })
  // },
  //My Profiles
  // {
  //   private: true,
  //   path: "/profiles/my-profile",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu:
  //     userRole === "ROOTADMIN" ? ProfileSideBarMenu : UserReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Profiles/MyProfile`),
  //     loading: Loader
  //   })
  // },
  //Report
  // {
  //   private: true,
  //   path: "/reports",
  //   sideBar: false,
  //   exact: true,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports`),
  //     loading: Loader
  //   })
  // },
  //Vehicle Summary
  // {
  //   private: true,
  //   path: "/reports/vehicle-summary",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/VehicleSummary`),
  //     loading: Loader
  //   })
  // },
  //Over Speed
  // {
  //   private: true,
  //   path: "/reports/over-speed",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/OverSpeed`),
  //     loading: Loader
  //   })
  // },
  //CAN Signal
  // {
  //   private: true,
  //   path: "/reports/can-signal",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/CANSignal`),
  //     loading: Loader
  //   })
  // },
  //Alert
  // {
  //   private: true,
  //   path: "/reports/alert",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/Alerts`),
  //     loading: Loader
  //   })
  // },

  // {
  //   private: true,
  //   path: "/reports/alert?alertType",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/Alerts`),
  //     loading: Loader
  //   })
  // },
  //Vehicle Instant Report
  // {
  //   private: true,
  //   path: "/reports/vehicle-instant",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/VehicleInstantReport`),
  //     loading: Loader
  //   })
  // },
  //Fuel Consumption Report
  // {
  //   private: true,
  //   path: "/reports/fuel-consumption",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/FuelConsumption`),
  //     loading: Loader
  //   })
  // },
  //Engine Over Run Report
  // {
  //   private: true,
  //   path: "/reports/engine-overrun",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/EngineOverRun`),
  //     loading: Loader
  //   })
  // },
  //Neutral Driving Report
  // {
  //   private: true,
  //   path: "/reports/neutral-driving",
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/NeutralDriving`),
  //     loading: Loader
  //   })
  // },
  //Setting
  // {
  //   private: true,
  //   path: "/settings",
  //   sideBar: false,
  //   exact: true,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Settings`),
  //     loading: Loader
  //   })
  // },

  // DTC
  // {
  //   private: true,
  //   path: "/dtc",
  //   sideBar: false,
  //   exact: true,
  //   // sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/Reports/DTC`),
  //     loading: Loader
  //   })
  // }
];

export default ROUTE;
