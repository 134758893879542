import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import TermsPopUp from '../Terms/TermsPopup';
import Modal from '../Common/Dialog/Modal';
import PrivacyPopUp from '../Terms/PrivacyPopup';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPrivacyModal: false,
      openTermsModal: false,
    }
  }


  toggleModal = () => {
    this.setState({
      openPrivacyModal: !this.state.openPrivacyModal
    });
  }


  openTermsModal = () => {
    this.setState({ openTermsModal: true })
  }

  closeTermsModal = () => {
    this.setState({ openTermsModal: false });
  }

  render() {
    const { openPrivacyModal, openTermsModal } = this.state;
    //console.log(privacyInfo,'privacy')
    return (
      <React.Fragment>
       
      </React.Fragment>
    );
  }
}
export default Footer;
