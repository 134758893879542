import React from "react";
import TruckImg from "../../assets/images/loading-truck.svg";
// import poof from "../../assets/images/poof.svg";

class Loader extends React.Component {
  render() {
    return (
      <div className="overlay">
        <div className="loader">
          <div className="loaderWraper">
            <div className="loaderimg">
              {/* <img src={poof} alt="poof" className="poof" /> */}
              <img src={TruckImg} alt="TruckImg" className="truckImg" />
            </div>
            <p>Loading, Please wait...</p>
          </div>
        </div>
      </div>
    );
  }
}
export default Loader;
