import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
	componentDidMount() {
		if (this.props.show) {
			this.handleOpen()
		}
	}

	handleOpen = () => {
		document.body.classList.add('overflow-hidden');
		document.addEventListener('click', this.handleOutsideClick, false);
	}

	componentWillUnmount() {
		if (this.props.show) {
			this.handleClose()
		}
	}

	handleOutsideClick = () => {
		this.props.onClose();
	}


	handleClose = () => {
		document.querySelector('body').classList.remove('overflow-hidden');

		document.removeEventListener('click', this.handleOutsideClick, false);
	}

	render() {
		// Render nothing if the "show" prop is false
		if (!this.props.show) {
			return null;
		}

		return (
			<div className="backdrop" >
				<div className="modal" >
					{this.props.children}


					<button onClick={this.props.onClose} className="closeButton" type="button">
						<svg class="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg>
					</button>
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	onClose: PropTypes.func.isRequired,
	show: PropTypes.bool,
	children: PropTypes.node
};

export default Modal;