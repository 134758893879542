/* eslint-disable */
import { useMsal } from '@azure/msal-react';
import React from 'react';
import signout from "../../assets/images/logout.png"


export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleSignOut = () => {
    localStorage.clear();
    instance.logoutRedirect();
  };

  return (
    <div
      className="signOutBtn"
      onClick={handleSignOut}
    >
      <img className="mr-1" src={signout} alt="logout" />
    </div>
  );
};
