import { combineReducers } from "redux";
import {
  DEFAULT_STATE,
  PENDING,
  FULFILLED,
  REJECTED,
  DEFAULT_STATE_FF_EF
} from "../Helpers/Constants";
import { updateSelectedPeriod } from '../components/Common/Tabs/TabAction';
//import { generateVehicleSummaryReports } from "../components/Reports/Action";
import { array } from "prop-types";

//reducer to manage UI state

const initUIState = {
  selectedPeriod: 'last7Days',
  selectedVehicleIds: []
}

function uiReducer(state=initUIState, action){
  switch(action.type){
    case "UPDATE_SELECTED_PERIOD": 
        return {...state,selectedPeriod:action.value}
    case "UPDATE_VEHICLE_ID":
        return {...state,selectedVehicleIds: [...action.value]}
    default:
      return state;
  }
}
/**
 * Common funtion to create the reducer
 * @param {*} reducerName name of operation
 * @param {*} defaultStateParam default states
 */
const createReducer = (reducerName, defaultStateParam) => {
  return (state, action) => {
    state = state || defaultStateParam || DEFAULT_STATE;
    switch (action.type) {
      case `${reducerName}_${PENDING}`:
      case `${reducerName}_${FULFILLED}`:
      case `${reducerName}_${REJECTED}`:
        return Object.assign({}, action.payload)
      default:
        return state;
    }
  };
};

const rootReducer = combineReducers({
  uiState: uiReducer,
  login: createReducer("LOGIN", DEFAULT_STATE_FF_EF),
  driverProfile: createReducer("DRIVER_PROFILE", DEFAULT_STATE_FF_EF),
  downloadProfiles: createReducer("PROFILE_DOWNLOAD", DEFAULT_STATE_FF_EF),
  editDriverProfile: createReducer("EDIT_DRIVER_PROFILE", DEFAULT_STATE_FF_EF),
  getNotificationList: createReducer(
    "GET_MYFLEET_NOTIFICATION_LIST",
    DEFAULT_STATE_FF_EF
  ),
  getMyFleetPlaBackVehiclesData: createReducer(
    "GET_MY_FLEET_PLAYBACK_VEHICLES",
    DEFAULT_STATE_FF_EF
  ),
  getCountryList: createReducer("GET_COUNTRY_LIST", DEFAULT_STATE_FF_EF),
  getMappedVehicles:createReducer("GET_VEHICLE_CATEGORY",DEFAULT_STATE_FF_EF),
  getCustomerList: createReducer("GET_CUSTOMER_LIST", DEFAULT_STATE_FF_EF),
  getGroupList: createReducer("GET_GROUP_LIST", DEFAULT_STATE_FF_EF),
  vehicleProfile: createReducer("VEHICLE_PROFILE", DEFAULT_STATE_FF_EF),
  getUserDetail: createReducer("GET_USER_DETAIL", DEFAULT_STATE_FF_EF),
  getGpsMyFleet: createReducer("GET_GPS_MYFLEET", DEFAULT_STATE_FF_EF),
  getCompanyForCustomerAdmin: createReducer(
    "GET_COMPANY_LIST_FOR_CUSTOMER",
    DEFAULT_STATE_FF_EF
  ),
  getCompanyForVehicleProfile: createReducer(
    "GET_COMPANY_LIST_FOR_VEHICLE",
    DEFAULT_STATE_FF_EF
  ),
  getCompanyForDriverProfile: createReducer(
    "GET_COMPANY_LIST",
    DEFAULT_STATE_FF_EF
  ),
  deleteDriverProfile: createReducer(
    "DELETE_DRIVER_PROFILE",
    DEFAULT_STATE_FF_EF
  ),
  getCategoryForVehicleProfile: createReducer(
    "GET_VEHICLE_CATEGORY_LIST",
    DEFAULT_STATE_FF_EF
  ),
  getVehicleTypeList: createReducer(
    "GET_VEHICLE_TYPE_LIST",
    DEFAULT_STATE_FF_EF
  ),
  getVehicleDashboardReport: createReducer(
    "GET_VEHICLES_DASHBOARD_REPORT_LIST",
    DEFAULT_STATE_FF_EF
  ),
  getVehiclesData: createReducer("GET_VEHICLES_LIST", DEFAULT_STATE_FF_EF),
  getVehicleDetail: createReducer("GET_VEHICLE_DETAIL", DEFAULT_STATE_FF_EF),
  getRegionList: createReducer("GET_REGION_LIST", DEFAULT_STATE_FF_EF),
  getSelectedUsers: createReducer(
    "GET_SELECTED_USER_LIST",
    DEFAULT_STATE_FF_EF
  ),
  getAdminGroup: createReducer("GET_ADMIN_USER_LIST", DEFAULT_STATE_FF_EF),
  deleteVehicleProfile: createReducer(
    "DELETE_VEHICLE_PROFILE",
    DEFAULT_STATE_FF_EF
  ),

  editVehicleProfile: createReducer(
    "EDIT_VEHICLE_PROFILE",
    DEFAULT_STATE_FF_EF
  ),
  customerAdmin: createReducer("CUSTOMER_ADMIN", DEFAULT_STATE_FF_EF),
  editCustomerAdmins: createReducer("EDIT_CUSTOMER_ADMIN", DEFAULT_STATE_FF_EF),
  deleteCustomerAdmins: createReducer(
    "DELETE_CUSTOMER_ADMIN",
    DEFAULT_STATE_FF_EF
  ),
  forgotPassword: createReducer("FORGOT_PASSWORD", DEFAULT_STATE_FF_EF),
  dealerProfile: createReducer("GET_DEALER_PROFILE", DEFAULT_STATE_FF_EF),
  saveDealerProfile: createReducer(
    "CREATE_DEALER_PROFILE",
    DEFAULT_STATE_FF_EF
  ),
  deleteDealerProfile: createReducer(
    "DELETE_DEALER_PROFILE",
    DEFAULT_STATE_FF_EF
  ),
  dashboardOverview: createReducer("DASHBOARD_OVERVIEW", DEFAULT_STATE_FF_EF),
  getMyProfile: createReducer("GET_MY_PROFILE", DEFAULT_STATE_FF_EF),
  saveMyProfile: createReducer("UPDATE_MY_ADMIN_PROFILE", DEFAULT_STATE_FF_EF),

  //MyFleet
  getGroups: createReducer("GET_GROUP", DEFAULT_STATE_FF_EF),
  getDriverAnalysisFromTrip: createReducer(
    "GET_DRIVER_FROM_TRIP",
    DEFAULT_STATE_FF_EF
  ),
  getNotification: createReducer("GET_NOTIFICATIONS", DEFAULT_STATE_FF_EF),
  getMyFleetVehicles: createReducer(
    "GET_MY_FLEET_VEHICLES",
    DEFAULT_STATE_FF_EF
  ),
  getGeoFenceData: createReducer("GET_GEOFENCE_DATA", DEFAULT_STATE_FF_EF),
  getGeoFenceList: createReducer("GET_GEOFENCE_LIST", DEFAULT_STATE_FF_EF),
  saveGeoFence: createReducer("SAVE_GEOFENCE", DEFAULT_STATE_FF_EF),
  updateGeFenceData: createReducer("POST_GEOFENCE_DATA", DEFAULT_STATE_FF_EF),
  myFleetData: createReducer("GET_MYFLEET_DATA", DEFAULT_STATE_FF_EF),
  deleteGeoFence: createReducer("DELETE_GEOFENCE", DEFAULT_STATE_FF_EF),
  getDTSNotificationData: createReducer(
    "GET_DTC_NOTIFICATION",
    DEFAULT_STATE_FF_EF
  ),
  //Daily Trip Analysis
  getDailyTripListWithDistance: createReducer(
    "GET_DAILY_TRIP_ANALYSIS_LIST",
    DEFAULT_STATE_FF_EF
  ),
  getMyFleetPlayback: createReducer(
    "GET_DAILY_TRIP_MY_FLEET_VEHICLE",
    DEFAULT_STATE_FF_EF
  ),
  // Reports
  getVehicleSummaryReportsData: createReducer(
    "VEHICLE_SUMMARY_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  getActiveVehicleData: createReducer(
    "GET_ACTIVE_VEHICLES",
    DEFAULT_STATE_FF_EF
  ),
  getLiveLocation:createReducer(
    "GET_LIVE_LOCATION",
    DEFAULT_STATE_FF_EF
  ),
  getTripUpdate: createReducer(
    "GET_TRIP_UPDATE",
    DEFAULT_STATE_FF_EF
  ),
 
  getTripRecordCount: createReducer(
    "GET_TRIP",
    DEFAULT_STATE_FF_EF
  ),
  generateVehicleSummaryReports: createReducer(
    "GENERATE_VEHICLES_SUMMARY_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateVehicleSummaryPDFReports: createReducer(
    "GENERATE_VEHICLES_SUMMARY_PDF_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  overSpeedReports: createReducer("OVER_SPEED_REPORTS", DEFAULT_STATE_FF_EF),
  generateOverSpeedReportsXls: createReducer(
    "OVER_SPEED_REPORTS_XLS",
    DEFAULT_STATE_FF_EF
  ),
  generateOverSpeedPDFReports: createReducer(
    "GENERATE_OVER_SPEED_PDF_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  fuelConsumptionReports: createReducer(
    "FUEL_CONSUMPTION_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateFuelConsumptionReportsXls: createReducer(
    "FUEL_CONSUMPTION_REPORTS_XLS",
    DEFAULT_STATE_FF_EF
  ),
  generateFuelConsumptionReportsPdf: createReducer(
    "FUEL_CONSUMPTION_REPORTS_PDF",
    DEFAULT_STATE_FF_EF
  ),
  getGroupsByReports: createReducer(
    "GET_GROUPS_OF_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  vehicleInstantaneousReports: createReducer(
    "VEHICLE_INSTANTANEOUS_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateVehicleInstantXLSReports: createReducer(
    "GENERATE_VEHICLE_INSTANT_XLS_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateVehicleInstantPDFReports: createReducer(
    "GENERATE_VEHICLE_INSTANT_PDF_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  getAlertTypes: createReducer("GET_ALERT_TYPES", DEFAULT_STATE_FF_EF),
  viewAlertReports: createReducer("VIEW_ALERT_REPORTS", DEFAULT_STATE_FF_EF),
  generateAlertReports: createReducer(
    "GENERATE_ALERT_XLS_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateAlertPDFReports: createReducer(
    "GENERATE_ALERT_PDF_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateCanSignalXlsReports: createReducer(
    "GENERATE_CAN_SINGAL_XLS_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  viewEngineOverRunReports: createReducer(
    "VIEW_ENGINE_OVER_RUN_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateEngineOverRunXlsReports: createReducer(
    "GENERATE_ENGINE_OVER_RUN_XLS_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateEngineOverRunPdfReports: createReducer(
    "GENERATE_ENGINE_OVER_RUN_PDF_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  viewNeutralDrivingReports: createReducer(
    "VIEW_NEUTRAL_DRIVING_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateNeutralDrivingXlsReports: createReducer(
    "GENERATE_NEUTRAL_DRIVING_XLS_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateNeutralDrivingPdfReports: createReducer(
    "GENERATE_NEUTRAL_DRIVING_PDF_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  // DTC Reports
  viewDTCReports: createReducer("VIEW_DTC_REPORTS", DEFAULT_STATE_FF_EF),
  generateDTCXls: createReducer(
    "GENERATE_DTC_XLS_REPORTS",
    DEFAULT_STATE_FF_EF
  ),
  generateDTCPdf: createReducer(
    "GENERATE_DTC_PDF_REPORTS",
    DEFAULT_STATE_FF_EF
  ),

  generateDTCPdf: createReducer(
    "GENERATE_USER_PDF",
    DEFAULT_STATE_FF_EF
  ),

  // settings

  addVehicleTypeData: createReducer("ADD_VEHICLE_TYPE", DEFAULT_STATE_FF_EF),
  getVehicleTypeData: createReducer("GET_VEHICLE_TYPE", DEFAULT_STATE_FF_EF),
  addVehicleCategoryData: createReducer(
    "ADD_VEHICLE_CATEGORY",
    DEFAULT_STATE_FF_EF
  ),
  getVehicleCategoryData: createReducer(
    "GET_VEHICLE_CATEGORY",
    DEFAULT_STATE_FF_EF
  ),
  getGeofenceTypeData: createReducer("GET_GEOFENCE_TYPE", DEFAULT_STATE_FF_EF),
  addGeofenceTypeData: createReducer("ADD_GEOFENCE_TYPE", DEFAULT_STATE_FF_EF),
  getGroupsData: createReducer("GET_GROUP", DEFAULT_STATE_FF_EF),
  addGroupsData: createReducer("ADD_GROUP", DEFAULT_STATE_FF_EF),
  getRegionData: createReducer("GET_REGIONS", DEFAULT_STATE_FF_EF),
  addRegionData: createReducer("ADD_REGIONS", DEFAULT_STATE_FF_EF),
  getCompaniesData: createReducer("GET_COMPANIES", DEFAULT_STATE_FF_EF),
  addCompanyData: createReducer("ADD_COMPANY", DEFAULT_STATE_FF_EF),
  getAlertPreferenceData: createReducer(
    "GET_USER_ALERT_PREFERENCE",
    DEFAULT_STATE_FF_EF
  ),
  addAlertPreferenceData: createReducer(
    "ADD_USER_ALERT_PREFERENCE",
    DEFAULT_STATE_FF_EF
  ),
  driverAnalysisList: createReducer(
    "GET_DRIVER_FROM_TRIP",
    DEFAULT_STATE_FF_EF
  ),
  changePasswordData: createReducer("CHANGE_PASSWORD", DEFAULT_STATE_FF_EF),
  getScalingFactorData: createReducer(
    "GET_SCALING_FACTOR",
    DEFAULT_STATE_FF_EF
  ),
  addScalingFactorData: createReducer(
    "ADD_SCALING_FACTOR",
    DEFAULT_STATE_FF_EF
  ),
  getVehicleUtilization: createReducer(
    "GET_VEHICLES_UTILIZATION",
    DEFAULT_STATE_FF_EF
  ),

  getAlertSettingsData: createReducer(
    "GET_ALERT_SETTINGS",
    DEFAULT_STATE_FF_EF
  ),
  addAlertSettingsData: createReducer(
    "ADD_ALERT_SETTINGS",
    DEFAULT_STATE_FF_EF
  ),

  setMaintainanceStatus:createReducer(
    "SET_MAINTAINANCE_STATUS",
    DEFAULT_STATE_FF_EF
  ),

  getMaintainanceStatus:createReducer(
    "GET_MAINTAINANCE_STATUS",
    DEFAULT_STATE_FF_EF
  ),

  driverAnalysisForPerformanceGraph: createReducer(
    "GET_DRIVER_PERFORMANCE_GRAPH",
    DEFAULT_STATE_FF_EF
  ),
  getDriverInsights: createReducer("GET_DRIVER_INSIGHTS", DEFAULT_STATE_FF_EF),
  getSpeedingCountForDriver: createReducer(
    "GET_DRIVER_SPEEDING_COUNT",
    DEFAULT_STATE_FF_EF
  ),
  getVehicleUtilizationGraph: createReducer(
    "GET_VEHICLES_UTILIZATION_GRAPH",
    DEFAULT_STATE_FF_EF
  ),
  generateXlsReportForTripManagement: createReducer(
    "GENERATE_DTA_XLS_REPORT",
    DEFAULT_STATE_FF_EF
  ),
  generatePdfReportForTripManagement: createReducer(
    "GENERATE_DTA_PDF_REPORT",
    DEFAULT_STATE_FF_EF
  ),
  getMileageData: createReducer(
    "GET_MILEAGE_DATA_WEEKLY",
    DEFAULT_STATE_FF_EF
  ),
  getUtilizationData: createReducer(
    "GET_UTILIZATION_DATA_WEEKLY",
    DEFAULT_STATE_FF_EF
  ),
  getAdblueData: createReducer(
    "GET_ADBLUE_DATA_WEEKLY",
    DEFAULT_STATE_FF_EF
  ),
  getUnsafeEventsData: createReducer(
    "GET_UNSAFE_EVENTS_DATA_WEEKLY",
    DEFAULT_STATE_FF_EF
  ),
  getVehicleDropdownData: createReducer(
    "GET_VEHICLE_DROPDOWN_DATA",
    DEFAULT_STATE_FF_EF
  ),
  getRPMData: createReducer(
  "GET_RPM_DATA_WEEKLY",
  DEFAULT_STATE_FF_EF
  ),
  getSpeedBandData: createReducer(
  "GET_SPEED_DATA_WEEKLY",
  DEFAULT_STATE_FF_EF
  ),

  exportData:createReducer(
    "EXPORT_DATA",
    DEFAULT_STATE_FF_EF
  ),
  getDTCTickerData: createReducer(
    "GET_DTC_TICKER_DATA",
    DEFAULT_STATE_FF_EF
  ),

  getActiveVehiclesTrip:createReducer("GET_TRUCKS",DEFAULT_STATE_FF_EF),
  getActiveDriversForNewTrip:createReducer("GET_DRIVERLIST",DEFAULT_STATE_FF_EF),
  createNewTrip:createReducer("CREATE_NEW_TRIP",DEFAULT_STATE_FF_EF),
  checkTruckDriverAvailability:createReducer("CHECK_DRIVER_AVAILABILITY",DEFAULT_STATE_FF_EF),
  getTripDetail:createReducer("GET_TRIP_DETAIL",DEFAULT_STATE_FF_EF),
  endTripManually:createReducer("END_TRIP",DEFAULT_STATE_FF_EF)

});

export default rootReducer;
