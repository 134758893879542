import React from "react";

import { NavLink } from "react-router-dom";

import {
  VehiSummary,
  OverSpeed,
  VehiInstant,
  AlertReport
} from "../../assets/images/svg/ProfileIcon.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

class SideMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggle: true,
      tab: ""
    };

    //Bind- Functionality
    this.toggleSideMenu = this.toggleSideMenu.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
  }

  toggleSideMenu() {
    this.setState({
      isToggle: !this.state.isToggle
    });
  }

  generateTabs(menus = [], defaultValue) {
    return menus.map((menu, key) => {
      const { name, icon, path, path2, path3 } = menu;
      return (
        <li
          className={
            defaultValue === path ||
            defaultValue === path2 ||
            defaultValue === path3
              ? "active"
              : "demo"
          }
          key={key}
        >
          <NavLink title={name} to={path}>
            <span className="MenuIcon">{icon()}</span>
            {name}
          </NavLink>
        </li>
      );
    });
  }

  render() {
    const { isToggle } = this.state;
    const { menus, defaultValue } = this.props;
    return (
      <React.Fragment>
        <nav className={isToggle ? "sideMenu Menuclose" : "sideMenu"}>
          <p
            className={isToggle ? "MenuToggle close" : "MenuToggle"}
            onClick={this.toggleSideMenu}
          >
            <FontAwesomeIcon icon={faBars} />
          </p>
          <ul>{this.generateTabs(menus, defaultValue)}</ul>
        </nav>
      </React.Fragment>
    );
  }
}
export default SideMenu;
