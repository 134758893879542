import React from "react";
import { Route, Redirect } from "react-router-dom";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SideBar from "../Common/SideMenu";
import { NotAuthroized } from "../../components/NotFound";
// const routesPermission = [
//   {
//     path: "/profiles/dealer"
//   },
//   {
//     path: "/profiles/customer-admin"
//   }
// ];

const Application = ({
  component: Component,
  isLoggedIn,
  auth,
  path,
  sideBarMenu,
  role,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      let auth = true;
      
        // routesPermission.map(val => {
        //   if (path === val.path) {
        //     auth = false;
        //   }
        // });
      return (
        <div className="container">
          {auth ? (
            <>
              {isLoggedIn() && <Header />}
              {isLoggedIn() && sideBarMenu && (
                <SideBar menus={sideBarMenu} defaultValue={path} />
              )}
              <Component path={"/owner-suite"} {...rest} />
            </>
          ) : (
            <NotAuthroized default />
          )}

          {isLoggedIn() && <Footer />}
        </div>
      );
    }}
  />
);

export default Application;
