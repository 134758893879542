import Utility from "../Helpers/Utility";
import { ls } from "./LocalStorage";
class Authorization {
  static ROLE_CONFIG_MANAGER = "CONFIG MANAGER";
  static ROLE_ADMIN = "ROOTADMIN";

  constructor() {
    this.authUser = null;
    this.authUserId = null;
    this.authRole = null;
    this.refreshRate = null;
  }

  /**
   * set auth user details to class property
   *
   * @return void
   */
  setAuthUser() {
   this.authUser = JSON.parse(ls.getItem("auth_user"));
   //this.authUser = ls.getItem('auth_user');

  }
  /**
   * set auth user details to class property
   *
   * @return void
   */
  getLocalRefreshRate() {
    this.refreshRate = JSON.parse(ls.getItem("refreshRate"));
    if (this.refreshRate) {
      return this.refreshRate * 1000;
    } else {
      return undefined;
    }
  }

  /**
   * check is active user is logged in
   *
   * @return boolean
   */
  isLoggedIn() {
    return typeof ls.getItem("auth_user") === "string";
    //
  }

  /**
   * Once user is logged in, redirect the user to view permission page
   * By default will redirect to 'dashboard' page.
   * If user does not have permission to access dashboard page,
   * find the view permission page from the his permission object
   * and redirect the user to respective path.
   *
   * @param {*} props
   */
  redirectAfterLogin(props) {
    let pathValue='';
    ls.setItem("refreshRate", 60);
    let path = JSON.parse(sessionStorage.getItem('proute'))
    if(path && path.pathname && path.search){
      pathValue= path.pathname + path.search
    }else{
      pathValue= '/owner-suite'
    }

    // window.location.assign(window.location.origin + pathValue);
    //ls.setItem("refreshRate", 60);
    window.location.assign(window.location.origin + pathValue);
    ls.setItem("showDTCPopup", true);
  }

  /**
   * check user is having the expected role
   *
   * @param role
   * @return boolean
   */
  isUserRole(role) {
    let user = this.getAuthUser();
    return (
      Utility.isObject(user) &&
      Utility.isObject(user.roles) &&
      user.roles[0] === role
    );
  }

  /**
   * check logged user is admin
   *
   * @return boolean
   */
  isAdmin() {
    return this.isUserRole(Authorization.ROLE_ADMIN);
  }

  /**
   * check logged user is config manager
   *
   * @return boolean
   */
  isConfigManager() {
    return this.isUserRole(Authorization.ROLE_CONFIG_MANAGER);
  }

  /**
   * get logged in user details
   *
   * @return boolean
   */
  getAuthUser() {
    if (this.isLoggedIn() && !this.authUser) {
      this.setAuthUser();
    }

    return this.authUser;
  }

  /**
   * get auth user identifier
   *
   * @return int
   */
  getAuthUserId() {
    let user = this.getAuthUser();
    return Utility.isObject(user) && user.id ? user.id : 0;
  }

  /**
   * get auth user role
   *
   * @return string
   */
  // getAuthUserRole() {
  //   let user = this.getAuthUser();
  //   return Utility.isObject(user) && user.roles.length ? user.roles[0] : "";
  // }

  /**
   * Get authentication access token
   *
   * @return string
   */
  getAccessToken() {
    const authUser = this.getAuthUser();
       return authUser && Utility.isString(authUser.token) ? authUser.token : null;
   //return authUser;

  }

  /**
   * login the user by setting it in local storage
   *
   * @return boolean
   */
  login(data) {
    if (typeof Storage !== "undefined") {

      ls.removeItem("auth_user");
      ls.setItem("auth_user", JSON.stringify(data));
    } else {
      console.error("local storage is not supported");
    }
  }

  /**
   * get logged in user details
   *
   * @return boolean
   */
  logout(props) {
    //clear URL stored in localStorage
    if (typeof Storage !== "undefined") {
      ls.clear();
      // ls.removeItem("auth_user");
      // ls.removeItem("userProfile");
      // ls.removeItem("refreshRate");
      // ls.removeItem("showDTCPopup");
      // window.location.assign(window.location.origin + "/");

      this.authUser = null;
    } else {
      console.error("local storage is not supported");
    }
  }
}

export default new Authorization();
