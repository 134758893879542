import React from 'react'
import Authorization from "../../Helpers/Authorization";
import signOut from "../../assets/images/logout.png";

export default class Modal extends React.Component{
    handleLogout=()=>{
        this.props.close();
        Authorization.logout()
    }
    render(){
        return(
            <>
             <div className="logoutModal">
                 <div className="logoutContainer">
                 <img className="modal-signout" src={signOut} alt="signout"/>
                 <div className="modal-label">Are you sure you want to logout ?</div>
                  
                     <button className="btn-yes" onClick={this.handleLogout}>YES</button>
                     <button className="btn-no" onClick={()=>this.props.close()}>NO</button>
                  
                 </div>
             </div>
            </>
        )
    }
}