import React from "react";
import Logo from "../../assets/images/BaratBenz-inner_logo.png";
import privacyData from '../../Helpers/Privacy';

function PolicyPopup(props) {
	return (
		<div className="privacyContainer">
			<div className="privacyLeft">
				<div className="logo footer-pop-logo">
					<img src={Logo} alt="logo" />
				</div>
				<p className="policy-header">PRIVACY POLICY</p>
				<p className="policy-subheader">This privacy policy explains how DICV (collectively “Truckonnect”, “we”, “us” or “our”) collects, use, share and process your information.</p>
				{
					privacyData && privacyData.data.map(data=>{
						if(data.num<=7){
							return(
								<>
								   <p className="policy-level-header">{data.num}){data.title}</p>
								   <p className="policy-subheader" dangerouslySetInnerHTML={{__html:data.info}}/>
								</>
							)
						}
					})
				}
			</div>
			<div className="privacyRight">

			{
					privacyData && privacyData.data.map(data=>{
						if(data.num>7){
							return(
								<>
								   <p className="policy-level-header">{data.num}){data.title}</p>
								   <p className="policy-subheader" dangerouslySetInnerHTML={{__html:data.info}}/>
								</>
							)
						}
					})
				}
			</div>
		</div>
	)
}

export default PolicyPopup;


