import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import toastr from "toastr";
import { setTitle, setMetaDescription } from "../../config";
import _ from "lodash";
import Authorization from "../../Helpers/Authorization";
import Application from "./Application";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SideBar from "../Common/SideMenu";
import Env from "../../Helpers/env";

/**
 * If we have a logged-in user, display the component, otherwise redirect to login page.
 */

const PrivateRoute = ({
  component: Component,
  private: isPrivate,
  path,
  sideBar,
  sideBarMenu,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (Authorization.isLoggedIn()) {
        return (
          <Application
            {...props}
            isLoggedIn={Authorization.isLoggedIn}
            auth={Authorization}
            component={Component}
            path={path}
            sideBarMenu={sideBarMenu}
           
          />
        );
      } else {
        sessionStorage.setItem("proute", JSON.stringify(props.location));
        toastr.error("Please login to continue.");
        return <Redirect to={{ pathname: Env.getEnv("REACT_APP_HOMEPAGE") }} />;
      }
    }}
  />
);
export default connect()(PrivateRoute);
